import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const Donation = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout
      title={siteTitle}
      logo={data.logo.childImageSharp.fluid}
      facebook={data.facebook.childImageSharp.fluid}
      instagram={data.instagram.childImageSharp.fluid}
    >
      <SEO
        title="All posts"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      <section className="section-introduction">
        <p className="introduction">Vos dons nous sont précieux !</p>
      </section>
      <iframe
        id="haWidget"
        allowtransparency="true"
        scrolling="no"
        src="https://www.helloasso.com/associations/orchestre-d-harmonie-d-evreux/formulaires/1/widget"
        className="hello-asso-iframe"
      ></iframe>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    facebook: file(relativePath: { eq: "facebook.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <Donation location={props.location} data={data} {...props} />
    )}
  />
)
